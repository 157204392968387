import { Injectable } from '@angular/core';

import { Command } from '../models/command';
import { Api } from './api';

@Injectable()
export class WaterQualityApi {

  constructor(public api: Api) { }

  query(greenhouse: string, params?: any) {
    return this.api.get('waterquality/' + greenhouse, params);
  }

  add(greenhouse: string, item: any) {
    return this.api.post('waterquality/' + greenhouse, item);
  }

  delete(greenhouse: string, item: any) {
  }

  upload(greenhouse: string, item: any) {
    return this.api.post('waterquality/' + greenhouse + '/photos', item);
  }

}
