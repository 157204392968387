import { Injectable } from '@angular/core';

import { Command } from '../models/command';
import { Api } from './api';

@Injectable()
export class UserApi {

  constructor(public api: Api) { }

  query(params?: any) {
    return this.api.get('user', params);
  }

  /*login(usrname:string, password:string) {
    return this.api.get('user/login', {usrname, password});
  }*/

  login(user: any) {
    return this.api.post('user/login', user);
  }

  add(item: Command) {
  }

  delete(item: Command) {
  }

}
