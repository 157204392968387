import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ClarityModule } from '@clr/angular';
import { CdsModule } from '@cds/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { Api } from 'src/providers/api';
import { WaterQualityApi } from 'src/providers/waterquality';
import { FishProductionApi } from 'src/providers/fishproduction';
import { HorticulturalProductionApi } from 'src/providers/hortiproduction';
import { UserApi } from 'src/providers/user';
import { PestManagementApi } from 'src/providers/pestmanagement';
import { ClimateApi } from 'src/providers/climate';

import '@cds/core/alert/register.js';
import { TraceMetalsApi } from 'src/providers/tracemetals';
import { IrrigationApi } from 'src/providers/irrigation';
import { LaboratoryApi } from 'src/providers/laboratory';
import { OCR } from '@ionic-native/ocr/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, ClarityModule, CdsModule, BrowserAnimationsModule, HttpClientModule,],
  providers: [
    Api,
    UserApi,
    ClimateApi,
    WaterQualityApi,
    FishProductionApi,
    HorticulturalProductionApi,
    PestManagementApi,
    LaboratoryApi,
    TraceMetalsApi,
    IrrigationApi,
    StatusBar,
    OCR,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
