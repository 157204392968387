import { Injectable } from '@angular/core';

import { Command } from '../models/command';
import { Api } from './api';

@Injectable()
export class FishProductionApi {

  constructor(public api: Api) { }

  query(greenhouse: string, params?: any) {
    return this.api.get('fishproduction/' + greenhouse, params);
  }

  add(greenhouse: string, item: any) {
    return this.api.post('fishproduction/' + greenhouse, item);
  }

  delete(greenhouse: string, item: any) {
  }

}
